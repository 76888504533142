import React from "react";
import FeatureSection from "../components/featureSection";

import ftimg from "../assets/images/tql/img@2x.png";
import ftimg1 from "../assets/images/tql/img@2x-1.png";
import ftimg2 from "../assets/images/tql/img@2x-2.png";
export default function WhyToSell() {
    return (
      <section
        className='section'
      >
        <div className="flex flex-column align-items ">
          <div className="green-header text-align">Trusted Online Marketplace</div>
          <h2 className="text-align">Why Sell with Top Quality Livestock?</h2>
          <p
            className='w-50-mobile text-align'
          >
            Top Quality Livestock is the premier online marketplace connecting
            a national network of verified buyers and sellers of livestock.
          </p>
        </div>

        <div
          className='flex gap-24'
        >
          <div
            className='greenLine'
            alt="why we are good!" />
          <div>
            <FeatureSection
              title="Sell to National Market"
              header="Free to list"
              text={ `Sell to anyone from industry giants to small-scale operations wanting to buy your product. 
              Ensure fair, competitive prices in the national market with buyers you can trust. 
              Get listings seen for free.` }
              image={ ftimg }

            />
            <FeatureSection
              title="Make More Sales"
              header="Get more offers"
              text={ `Access to TQL’s marketplace means you are seen by thousands of potential buyers. 
              As soon as you are verified to sell, get offers from all over the country.` }
              image={ ftimg1 }

            />
            <FeatureSection
              title="Quick & Easy Process"
              header="Instant messaging"
              text={ `Connect quickly with buyers and negotiate directly until you are satisfied. 
              Your cattle don’t leave the ranch until you meet a buyer you like.` }
              image={ ftimg2 }
            />
          </div>

        </div>
      </section>
    );
}
