import React from "react";
import IconWithText from "./iconWithText";
export default function MainSection() {
    return (
      <div
        className='main'
      >
        <h3>
          Top Quality Livestock
        </h3>
        <h1>
          Sell Your Cattle <br></br>
          to Nationwide Buyers
        </h1>
        <div className="flex align-items flex-center gap-24 main-icons">
          <IconWithText
            text="Quick & Easy Process"
          />
          <IconWithText
            text="Top-Dollar Offers "

          />
          <IconWithText
            text="Add Listings for Free"

          />
        </div>
        <div>
          <span
            className='main-getoffers'
          >
            Get Approved, Get Offers, Get Paid
          </span>
        </div>
        <div>
          <button
            className='button button-action'
          >
            <a
              href="/register"
              target='_self'>
              Register to Sell
            </a>
          </button>
        </div>
      </div>
    );
}
