import React from "react";
import newPicture from "../assets/images/tql/loginTestimonials.png";
export default function ShowCase() {
    return (
      <div
        className='sc'
      >
        <img
          src={ newPicture }
          alt='start selling today!' />
      </div>
    );
}
