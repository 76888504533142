import React from "react";
import AppRoutes from "./routes/routes";
import Footer from "./components/footer";
function App() {
    return (
      <div className="App">
        <AppRoutes />
        <Footer />
      </div>
    );
}

export default App;
