import React from "react";
import mainLogo from "../assets/images/tql/mainLogo.svg";
import mobileNotch from "../assets/images/tql/mobile-notch.svg";

export default function Header() {
    return (
      <header >
        <div className="flex flex-between align-items">
          <div className="flex align-items gap">
            <div className="header-logo">
              <a href="/">
                <img
                  src={ mainLogo }
                  alt="TQL"
                />
              </a>
            </div>
            <span
              className='header-about mobile-hidden'
            >
              <a
                href="#about_us"
                className="no-decor">
                About Us
              </a>
            </span>
          </div>


          <div className='flex align-items gap' >
            <div className="flex align-items gap mobile-hidden">
              <span >Call us</span>
              <img
                src={ mobileNotch }
                alt="phone us via this number" />

              <a href="tel:8883131597">(888)-313-1597</a>
            </div>
            <button
              className='button button-transparent'
            >
              <a
                href="/register"
                target="_self">
                Sell Your Livestock
              </a>
            </button>

          </div>
        </div>
      </header>
    );
}
