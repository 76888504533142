import React from "react";
import image2 from "../assets/images/tql/image2.jpg";

export default function WhoWeAre() {
    return (
      <section
        className="section"
        id="about_us">
        <div className="flex flex-between flex-mobile gap-2vw align-items ">
          <img
            className="ourHistoryImage"
            src={ image2 }
            alt="our history and family" 
          />
          <div className="w-40-mobile">
            <div className="title3">
              <div className="green-header">Who We Are</div>
              <div className="">
                <h2>The Top Quality <br></br> Livestock Family</h2>
              </div>
            </div>
            <div className="flex flex-column flex-evenly gap-24 ">
              <p
                className="f-18"
              >
                Top Quality Livestock got its start in Austin, Texas with one goal: provide farmers and ranchers
                with digital solutions to help them become more efficient,
                profitable, and competitive in national markets.
              </p>
              <p
                className="f-18"
              >
                Our team of ranchers and tech innovators is helping build
                a stronger future for agriculture, as a trusted partner of operations across the country.
              </p>
              <p 
                className="f-18"
              >
                We are driven by the belief that agriculture is the backbone of this country
                and without farmers and ranchers creating a secure and independent food supply,
                there is no United States.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
}
