/* eslint-disable max-len */
import React from "react";
import ImageScroll from "./imageScroll";
import usFlag from "../assets/images/tql/usflag.png";
import { useNavigate } from "react-router-dom";

const Card = ({ title, location, price, priceMode, images }) => {
    const navigate = useNavigate();

    return (
      <div
        className="card"
        onClick={ () => {
            navigate("/register");
        } }
      >
        <div>
          <ImageScroll
            images={ images }
          />

        </div>

        <div className="flex flex-column flex-start">
          <header
          >
            {title}
          </header>
          <p
            className="card-description"

          >
            Commercial beef / Beef Cattle
          </p>
        </div>
        <div
          className="card-location"
        >
          <div className="flex align-items gap">
            <img
              src={ usFlag }
              alt="Hereford, TX (252 mi. away)"
              width="20" />

            {location}
          </div>

        </div>
        <div className="flex align-items card-price">
          <p>
            {price}
          </p>
          <p >
            <span >/</span>
            {priceMode}
          </p>
        </div>
      </div>
    );
};

export default Card;