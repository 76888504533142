/* eslint-disable max-len */
import React from "react";
import Card from "./card";
import gallery1 from "../assets/images/card1.jpeg";
import gallery4 from "../assets/images/card4.jpeg";

import gallery2 from "../assets/images/tql/gallery2.png";
import gallery3 from "../assets/images/tql/gallery3.png";

export default function Cards() {
    const images = [
      gallery1, 
      gallery3,
      gallery2,
      gallery4
    ];
    return (
      <section
        className='section mobile-hidden'
      >
        <div className="green-header">TOP CATTLE OFFERS FROM AROUND THE COUNTRY</div>
        <div className="pad-bot-40">
          <h2>Register today and get your cattle listed.</h2>
        </div>
        <div className="flex flex-gallery flex-between gap">
          <Card
            location="Texas"
            title="12 Red Angus"
            price="$3,200"
            priceMode="head"
            images={ [images[0]] }
          />
          <Card
            location="Kansas"
            title="17 Brangus"
            price="$2,200"
            priceMode="head"
            images={ [images[1]] }

          />
          <Card
            location="Texas"
            title="8 Beefmaster"
            price="$2,500"
            priceMode="head"
            images={ [images[2]] }

          />
          <Card
            location="Texas"
            title="1 Braham"
            price="$5,500"
            priceMode="head"
            images={ [images[3]] }

          />
        </div>
        <button className="button button-action m-top-auto pad-73-width ">
          <a
            href="/register"
            target='_self'>
            Register to Sell
          </a>
        </button>
      </section>
    );
}
