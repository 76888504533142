import React from "react";
import icon from "../assets/images/tql/checkMark.svg";
export default function IconWithText({ text }) {
    return (
      <div
        className='iconText'
      >
        <img
          src={ icon }
          alt={ text }
          width="16"
          height="16"
        />
        <span>
          {text}
        </span>
      </div>
    );
}
