import React from "react";
import ShowCase from "./showCase";


export default function StartSelling() {

    const sellings = [
      "Create an account",
      "Get approved to sell",
      "Add cattle listing with breed, age, weight",
      "Start appearing in our catalog for thousands of nationwide buyers searching for livestock everyday!"
    ];

    return (
      <section
        className='section start-selling'
      >
        <div className="flex flex-between gap-2vw ">
          <ShowCase />
          <div >
            <div className="green-header">Get approved today</div>
            <h2 >Start Selling Around <br></br> the Country</h2>
            <div>
              <ul
                className='list'
              >
                {sellings.map((feature, index) => {
                    return <li
                      key={ `${index}-${feature}` }
                    >
                      <div
                        className='circle'
                      >
                        <span
                          className='number'
                        >
                          {index + 1}
                        </span>
                      </div>
                      <span
                        className='list-text'
                      >{feature}
                      </span>
                    </li>;
                })}
              </ul>

            </div>
            <button className="button button-action">
              <a
                href="/register"
                target='_self'>
                Register to Get Started
              </a>
            </button>
          </div>
        </div>

      </section >
    );
}
