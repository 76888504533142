import React from "react";
import fbIcon from "../assets/images/tql/fbIcon.svg";
export default function Footer() {
    return (
      <section
        className='footer'
      >
        <span>
          Let&apos;s keep in touch!
        </span>
        <div className="footer-details">
          <a
            href="mailto:info@frontiersmarket.com"
            className="no-decor"
          >
            <p>
              info@frontiersmarket.com
            </p>
          </a>
          <div className="flex flex-between align-items">
            <a
              href="tel:+15123874314"
              className="clean-anchor"
            >
              <p>
                +1 512-387-4314
              </p>
            </a>
            <a
              href="https://www.facebook.com/FrontiersMarket"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ fbIcon }
                width="20"
                height="20"
                alt="Our facebook page"
              />

            </a>
          </div>

        </div>

        <div
          className='footer-copyright flex flex-mobile flex-between'
        >
          <div className="flex gap-24">
            <p>
              Terms of Service
            </p>
            <p>
              Privacy Policy
            </p>
          </div>
          <p
            className='footer-rights'
          >
            Copyright © Frontiers Market 2023. All Rights Reserved.
          </p>
        </div>
      </section>
    );
}
