import React from "react";
import Header from "../components/header";
import Form from "../components/form";
import WhoWeAre from "../components/whoWeAre";

export default function Register() {

    const sellings = [
      "Create an account",
      "Get approved to sell",
      "Add cattle listing with breed, age, weight",
      "Start appearing in our catalog for thousands of nationwide buyers searching for livestock everyday!"
    ];

    return (
      <div>
        <Header />
        <section

          className='section section-register'>
          <Form />
          <div>
            <div className="green-header">Get approved today</div>
            <h2 >Start Selling Around <br></br> the Country</h2>
            <div>
              <ul
                className='list list-white'
              >
                {sellings.map((feature, index) => {
                    return <li
                      key={ `${feature}-${index}` }
                    >
                      <div
                        className='circle'
                      >
                        <span
                          className='number'
                        >
                          {index + 1}
                        </span>
                      </div>
                      <span
                        className='list-text'
                      >{feature}
                      </span>
                    </li>;
                })}
              </ul>

            </div>
          </div>


        </section>
        <WhoWeAre />
      </div>
    );
}
