import React from "react";

export default function FeatureSection({ title, header, text, image }) {
    return (
      <div
        className='feature flex flex-mobile'
      >
        <div className="feature-text">
          <div className="green-header">
            {header}
          </div>
          <div className="pad-bot-10">
            <h3>{title}</h3>
          </div>
          <div>
            <p>
              {text}
            </p>
          </div>
        </div>
        <div className="feature-image">
          <img
            src={ image }
            alt={ title } />
        </div>
      </div>
    );
}
