/* eslint-disable no-unused-vars */
import React, {  useState } from "react";
import Watched from "./watched";

export default function ImageScroll({ images }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const handlePrev = () => {
        setCurrentSlide((currentSlide - 1 + images.length) % images.length);
    };
    const [showText, setShowText] = useState(false);
    const handleNext = () => {
        setCurrentSlide((currentSlide + 1) % images.length);
    };


    return (
      <div
        className="slider"
        onMouseOver={ () => setShowText(true) }
        onMouseOut={ () => setShowText(false) }
      >
        <div className="slides">
          {/* <Watched /> */}
          <button
            className="arrow prev"
            onClick={ handlePrev }>
            &#10094;
          </button>
          <button
            className="arrow next"
            onClick={ handleNext }>
            &#10095;
          </button>
          <div
            className='slider-counter'
          >
            <span>
              {currentSlide + 1}
            </span>
            /
            {images.length}
          </div>
          {images.map((item, index) => {
              return(        <div
                key={ item }
                className={ index === currentSlide ? "slide active" : "slide" }
              >
                <img
                  src={ item }
                  alt={ `Slide ${index}` } />
              </div>);
          }
    
          )}
        </div>
        {showText 
          ?   <div
              className="card-hover-text"
          >
            List Your Cattle Here
          </div>: null
        }
      </div>
    );
}
