import React from "react";
import Header from "../components/header";
import MainSection from "../components/mainSection";
import WhoWeAre from "../components/whoWeAre";
import StartSelling from "../components/startSelling";
import Cards from "../components/cards";
import WhyToSell from "../components/whyToSell";
export default function Main() {
    return (
      <div>
        <Header />
        <MainSection />
        <WhyToSell />
        <Cards />
        <StartSelling />
        <WhoWeAre />
      </div >
    );
}
