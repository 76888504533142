import React, { useState } from "react";
import { db, auth } from "../firebase.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";

export default function Form() {
    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      streetAddress: "",
      zipCode: ""
    });
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const pattern = /^[A-Za-z ]*$/;

    const handleChange = (event) => {
        const { name, value } = event.target;

        if(name === "firstName" || name === "lastName"){
          const isValid = pattern.test(value);
          if (isValid){
            setFormData({
              ...formData,
              [name]: value
            });
            return;
          } else {
            return;
          }
        }

        setFormData({
          ...formData,
          [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
          try {
            const userSnapshot = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            delete formData.password;
            delete formData.confirmPassword;
            await setDoc(doc(db, "users", userSnapshot.user.uid), {
              _id: userSnapshot.user.uid,
              ...formData
            });
            setSuccess(true);
            setFormData({      
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              password: "",
              confirmPassword: "",
              streetAddress: "",
              zipCode: ""});
          } catch (error) {
            setSuccess(false);
            if (error.code === "auth/email-already-in-use") {
              setErrors({ upload: "You have already registered with this email" });
              return;
            }
            setErrors({ upload: "We have an error. Please try again or contact the support" });
          }
        }
    };

    const validate = () => {
        const errors = {};

        if (!formData.firstName) {
          errors.firstName = "First name is required";
        }

        if (!formData.lastName) {
          errors.lastName = "Last name is required";
        }

        if (!formData.email) {
          errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          errors.email = "Email is invalid";
        }

        if (!formData.phone) {
          errors.phone = "Phone is required";
        } else if (!/^\+?1?[- .]?(\d{3})[- .]?(\d{3})[- .]?(\d{4})$/.test(formData.phone)) {
          errors.phone = "Please provide US phone number";
        }

        if (!formData.password) {
          errors.password = "Password is required";
        } else if (formData.password.length < 6) {
          errors.password = "Password must be at least 6 characters";
        }

        if (!formData.confirmPassword) {
          errors.confirmPassword = "Confirm password is required";
        } else if (formData.confirmPassword !== formData.password) {
          errors.confirmPassword = "Passwords do not match";
        }

        if (!formData.streetAddress) {
          errors.streetAddress = "Street address is required";
        }

        if (!formData.zipCode) {
          errors.zipCode = "Zip code is required";
        } else if (!/^\d{5}(?:[-\s]\d{4})?$/.test(formData.zipCode)) {
          errors.zipCode = "Zip code  should be in US format with 4 or 5 digits";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };


    return (

      <form onSubmit={ handleSubmit }>
        <div className="form">
          <div 
            className="form-input"
          >
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={ formData.firstName }
              onChange={ handleChange }
              required
            />
            {errors.firstName && <div className="error">{errors.firstName}</div>}
          </div>

          <div
            className="form-input"
          >
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={ formData.lastName }
              onChange={ handleChange }
              required />
            {errors.lastName && <div className="error">{errors.lastName}</div>}
          </div>
          <div
            className="form-input"
          >
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={ formData.email }
              onChange={ handleChange }
              required />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
          <div
            className="form-input"
          >
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={ formData.phone }
              onChange={ handleChange }
              required />
            {errors.phone && <div className="error">{errors.phone}</div>}
          </div>
          <div
            className="form-input"
          >

            <input
              type="password"
              name="password"
              placeholder="Password"
              value={ formData.password }
              onChange={ handleChange }
              required />
            {errors.password && <div className="error">{errors.password}</div>}

          </div>
          <div
            className="form-input"
          >
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={ formData.confirmPassword }
              onChange={ handleChange }
              required />

            {errors.confirmPassword && <div className="error">{errors.confirmPassword}</div>}
          </div>
          <div
            className="form-input"
          >
            <input
              type="text"
              name="streetAddress"
              placeholder="Street Address"
              value={ formData.streetAddress }
              onChange={ handleChange }
              required />
            {errors.streetAddress && <div className="error">{errors.streetAddress}</div>}
          </div>

          <div
            className="form-input"
          >
            <input
              type="text"
              name="zipCode"
              placeholder="Zip Code"
              value={ formData.zipCode }
              onChange={ handleChange }
              required />
            {errors.zipCode && <div className="error">{errors.zipCode}</div>}
          </div>
        </div>

        <button
          type="submit"
          className='button button-action'
        >Register</button>
        <div>
          {success ? <span
            className='success'
          >Success! We received your information!</span> : errors.upload ? 
            <span
              className="error"
            >{errors.upload}</span>
            : null}
        </div>
      </form>
    );
}
