
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyA0Iy1rgwhOhTJ9BNmbbbYorSXoAcA8a7s",
  authDomain: "topqualitylivestock.firebaseapp.com",
  projectId: "topqualitylivestock",
  storageBucket: "topqualitylivestock.appspot.com",
  messagingSenderId: "833692869158",
  appId: "1:833692869158:web:875681a0af226ec3b03ce7",
  measurementId: "G-XM7N9NKZ6J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export {
  db, app, analytics, auth
};